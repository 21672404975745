import {Helmet} from 'react-helmet-async';
import {connect} from 'react-redux';
import React, {Component} from 'react';

import MarketLayout from 'web/components/market_layout';

import TermsLayout from '../components/terms_layout';

class AlcoholPage extends Component {
  static reducer(state, action) {
    return MarketLayout.reducer(state, action);
  }

  renderAlcoholTermsOfUse() {
    return (
      <div>
        <h3>Alcohol Terms of Use</h3>
        <ul>
          <li>
            Customers who purchase alcoholic beverages must be 21 years of age or older. Anyone
            under the age of 21 is prohibited from purchasing alcohol and is in violation of these
            terms.
          </li>
          <li>For orders that contain alcoholic beverages, customers must:</li>
          <ul>
            <li>Be present for delivery.</li>
            <li>
              Show proof of legal age with a valid government-issued photo ID at the time of
              delivery, such as such as a passport, U.S. driver’s license, state ID card, or
              military ID. Sorry, we cannot accept student, employee, or expired government ID cards
              as proof of age.
            </li>
          </ul>
          <li>
            If these 2 requirements are not met, the entire order will not be delivered and the
            order will be treated as a cancelled/missed order. Please refer to our policy for{' '}
            <span className="alcohol_terms__link">
              <a href="https://help.goodeggs.com/hc/en-us/articles/360007374912-What-happens-if-I-miss-a-delivery-">
                missed and cancelled orders
              </a>
            </span>
            .
          </li>
          <li>
            No orders containing alcohol will be delivered to any visibly intoxicated persons.
          </li>
          <li>
            We reserve the right to terminate and/or suspend accounts, or cancel orders that have
            been placed, if we believe the customer is in violation of the California Alcoholic
            Beverage Control Act. Violations include but are not limited to:
          </li>
          <ul>
            <li>
              A person under the age of 21 who tries to purchase alcohol or successfully purchases
              alcohol.
            </li>
            <li>
              A person who furnishes alcohol or causes alcohol to be furnished to a person under the
              age of 21.
            </li>
          </ul>
        </ul>
        <h4>
          NOTICE TO CUSTOMERS: <br />
          Pursuant to Section 25658.4 Business and Professions Code
        </h4>
        <p>
          Good Eggs will not sell alcoholic beverages in violation of the California Alcoholic
          Beverage Control Act.
        </p>
        <h4>
          We will refuse to sell an alcoholic beverage to any customer if we reasonably suspect
          that:
        </h4>
        <p>
          The customer is under the age of 21 years; (2) the customer looks or acts intoxicated; (3)
          the request to buy an alcoholic beverage is made between the hours of 2:00 am and 6:00 am
          on any day or in violation of legally required shorter hours of sale*; (4) the customer
          intends to drink the alcoholic beverage in this store or on adjacent property immediately
          outside this store; or (5) any other violation of the California Alcoholic Beverage
          Control Act will occur as a result of the sale.
        </p>
        <p>
          *Customers can checkout on goodeggs.com at any time of day; Good Eggs will not fulfill a
          request to buy an alcohol beverage, process or deliver orders between 2:00 am and 6:00 am
          on any day or in violation of legally required shorter hours of sale.
        </p>
        <h4 className="alcohol_terms__terms-header">FINES AND PENALTIES</h4>
        <p>
          for the Sale or Furnishing of Alcoholic Beverages to, or the Purchase of Alcoholic
          Beverages by, Person under age 21.
        </p>
        <h4>
          For the Person Under Age 21 Who{' '}
          <span className="alcohol_terms__underlined">Tries to Purchase Alcohol</span>:
        </h4>
        <p>
          Up to $100 fine and/or 24-32 hours of community service; second offense, up to $250 fine
          and/or 36-48 hours of community service (and a one-year suspension or delay of the
          person’s license).
        </p>
        <h4>
          For the Person Under Age 21 Who{' '}
          <span className="alcohol_terms__underlined">Purchases Alcohol</span>:
        </h4>
        <p>
          A $250 fine and/or 24-32 hours of community service, second offense, up to a $500 fine
          and/or 36-48 hours of community service (and a one-year suspension or delay of the
          person’s license).
        </p>
        <h4>
          For the Person Who <span className="alcohol_terms__underlined">Furnishes</span> Alcohol{' '}
          <span className="alcohol_terms__underlined">Or Causes</span> Alcohol to be Furnished to a
          Person Under Age 21:
        </h4>
        <p>
          A $1,000 fine and at least 24 hours of community service (and a one-year suspension or
          delay of the furnisher’s driver’s license if the furnisher is under age 21). If great
          bodily injury or death occurs, the penalty is 6-12 months county jail and/or a $1,000
          fine.
        </p>
        <h4>
          For the Person Who <span className="alcohol_terms__underlined">Sells</span> Alcohol to a
          Person Under Age 21:
        </h4>
        <p>
          A $250 fine and/or 24-32 hours of community service, second offense, up to a $500 fine
          and/or 36-48 hours of community service (and a one-year suspension or delay of the
          seller’s license if the seller is under age 21).
        </p>
        <h4 className="alcohol_terms__note">
          In addition, the Department of Alcoholic Beverage Control (ABC) will file charges to
          suspend or revoke this store’s license to sell alcoholic beverages.
        </h4>
        <h4>---</h4>
        <p>
          <strong>Warning:</strong> Drinking Distilled Spirits, Beer, Coolers, Wine and Other
          Alcoholic Beverages May Increase Cancer Risk and, During Pregnancy, Can Cause Birth
          Defects.
        </p>
        <h4>---</h4>
        <h4 className="alcohol_terms__terms-header">RETAIL OPERATING STANDARDS</h4>
        <p>
          (1) A prominent, permanent sign or signs stating “No Loitering is allowed on or in front
          of these premises” shall be posted in a place that is clearly visible for patrons of the
          licensee. The size, format, form, placement and languages of the sign or signs shall be
          determined by the department. This paragraphs shall apply to a licensee only upon written
          notice to the licensee from the department. The department shall issue this written notice
          only upon a request, from local law enforcement agency in whose jurisdiction the premises
          are located, that is supported by substantial evidence that there is loitering adjacent to
          the premises. (Emphasis added.)
        </p>
        <p>
          (2) A prominent, permanent sign or signs stating “NO OPEN ALCOHOLIC BEVERAGE CONTAINERS
          ARE ALLOWED ON THESE PREMISES” shall be posted in a place that is clearly visible to
          patrons of the licensee. The size, format, form, placement and languages of the sign or
          signs shall be determined by the department. This paragraph shall apply to a licensee only
          upon written notice to the licensee from the department. The department shall issue this
          written notice only upon a request, from local law enforcement agency in whose
          jurisdiction the premises are located, that is supported by substantial evidence that
          there is drinking in public adjacent to the premises. (Emphasis added.)
        </p>
        <p>
          (3) No alcoholic beverages shall be consumed on the premises of an off-sale retail
          establishment, and no alcoholic beverages shall be consumed outside the edifice of an
          on-sale retail establishment.
        </p>
        <p>
          (4) The exterior of the premises, including adjacent public sidewalks and all parking lots
          under the control of the licensee, shall be illuminated during all hours of darkness
          during which the premises are open for business in a manner so that persons standing in
          those areas at night are identifiable by law enforcement personnel. However, the required
          illumination shall be placed so as to minimize interference with the quiet enjoyment of
          nearby residents of their property.
        </p>
        <p>
          (5) Litter shall be removed daily from the premises, including adjacent public sidewalks
          and all parking lots under the control of the licensee. These areas shall be swept or
          cleaned, either mechanically or manually, on a weekly basis to control debris.
        </p>
        <p>
          (6) Graffiti shall be removed from the premises and all parking lots under the control of
          the licensee within 72 hours of application. If the graffiti occurs on a Friday or weekend
          day, or on a holiday, the licensee shall remove the graffiti within 72 hours following the
          beginning of the next weekday.
        </p>
        <p>
          (7) No more than 33 percent of the square footage of the windows and clear doors of an
          off-sale premises shall be advertising or signs of any sort, and all advertising and
          signage shall be placed and maintained in a manner that ensures that law enforcement
          personnel have a clear and unobstructed view of the interior of the premises, including
          the area in which the cash registers are maintained, from the exterior public sidewalk or
          entrance to the premises. However, this latter requirement shall not apply to premises
          where there are no windows, or where existing windows are located at the height that
          precludes a view of the interior of the premises to a person standing outside the
          premises.
        </p>
        <p>
          (8) Upon request of the local law enforcement agency in whose jurisdiction the licensed
          premises are located or at the discretion of the department, each public telephone located
          on off-sale premises (or located in an adjacent area under the control of the off-sale
          licensee) shall be equipped with devices or mechanisms that prevent persons from calling
          into that public telephone.
        </p>
        <p>
          (9) Every license retailer who sells or rents video recordings of harmful matter, as
          defined by Section 313 of the Penal Code, shall create an area within his or her business
          establishment for the placement of video recordings of harmful matter and for any material
          that advertises the sale or rental of these video recordings. This area shall be labeled
          “adults only.” The licensed retailer shall make reasonable efforts to arrange the video
          recordings in this area in such a way that minors may not readily access the video
          recordings or view the video box covers. The failure to create and label the “adults only”
          area is an infraction punishable by a fine of not more than one hundred dollars ($100).
          The failure to place a video recording or advertisement, regardless of its content, in
          this area shall not constitute an infraction.
        </p>
        <p>
          (19) A copy of the applicable operating standards shall be available during normal
          business hours for viewing by the general public.
        </p>
        <h4>Changes to Terms</h4>
        <p>
          Good Eggs reserves the right, in its sole discretion, to change the Terms under which
          goodeggs.com is offered. The most current version of the Terms will supersede all previous
          versions. Good Eggs encourages you to periodically review the Terms to stay informed of
          our updates.
        </p>
        <h4>Contact Us</h4>
        <p>We welcome your questions or comments regarding the Terms:</p>
        <p>Good Eggs</p>
        <p>2000 Maritime St.</p>
        <p>Oakland, California 94607</p>
        <p>
          Email Address:{' '}
          <a href={`mailto:${this.props.alcoholPage.emailAddress}`}>
            {this.props.alcoholPage.emailAddress}
          </a>
        </p>
        <p>
          Telephone number:{' '}
          <a href={`tel:${this.props.alcoholPage.phoneNumber}`}>
            {this.props.alcoholPage.phoneNumber}
          </a>
        </p>
        <h4>---</h4>
        <p>Effective Date: May 25, 2017</p>
      </div>
    );
  }

  render() {
    return (
      <MarketLayout
        categories={this.props.categories}
        foodhub={this.props.foodhub}
        user={this.props.user}
        disableUpcomingOrdersBanner
      >
        <Helmet>
          <title>Alcohol Terms of Use | Good Eggs</title>
        </Helmet>

        <TermsLayout currentSlug="alcohol">{this.renderAlcoholTermsOfUse()}</TermsLayout>
      </MarketLayout>
    );
  }
}

AlcoholPage.pageName = 'Good Eggs Alcohol Terms of Use';

export default connect((state) => state)(AlcoholPage);
